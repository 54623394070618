































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api } from "@/store/modules/measureProcess";

import {
  MeasureProcessStepDirectMeasure,
  Signature,
} from "@/store/modules/measureProcess/types";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

@Component({
  components: {},
})
export default class AMeasureProcessStepDirectMeasure extends Vue {
  description: string = "";
  user: null | IUserRef = null;
  date: null | string = null;

  @Prop({
    type: MeasureProcessStepDirectMeasure,
  })
  readonly doc!: MeasureProcessStepDirectMeasure;

  @Action(api.actions.startDirectMeasure, {
    namespace: api.namespace,
  })
  startDirectMeasure!: (payload: {
    stepId: string;
    dueDate: string;
    responsible: IUserRef;
    description: string;
  }) => Promise<void>;

  @Action(api.actions.finalizeDirectMeasure, {
    namespace: api.namespace,
  })
  finalizeDirectMeasure!: (payload: { stepId: string }) => Promise<void>;

  @Action(api.actions.verifyDirectMeasure, {
    namespace: api.namespace,
  })
  verifyDirectMeasure!: (payload: {
    stepId: string;
    signature: Signature;
    comment: string;
  }) => Promise<void>;

  startStep() {
    if (this.date !== null && this.user !== null) {
      this.startDirectMeasure({
        stepId: this.doc.id,
        dueDate: this.date,
        responsible: this.user,
        description: this.description,
      });
    }
  }
  finalizeStep() {
    this.finalizeDirectMeasure({
      stepId: this.doc.id,
    });
  }
  verifyStep(payload: { signature: Signature; comment: string }) {
    this.verifyDirectMeasure({
      stepId: this.doc.id,
      ...payload,
    });
  }
  discardStep() {}
}
